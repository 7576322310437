
import axios from '@/common/js/request'

// 居民安检周期下拉
export function arumPeriodPulldown(params){  
    const arumPeriodPulldown = axios({
        url: '/taskCard/arumPeriodPulldown',
        method: 'get',
        params
    })
    return arumPeriodPulldown
}
// 居民周期下任务排名
export function arumTaskRank(params){  
    const arumTaskRank = axios({
        url: '/taskCard/arumTaskRank',
        method: 'get',
        params
    })
    return arumTaskRank
}
// 非居民用户分类下拉
export function aaumUserTypePulldown(params){  
    const aaumUserTypePulldown = axios({
        url: '/taskCard/aaumUserTypePulldown',
        method: 'get',
        params
    })
    return aaumUserTypePulldown
}
// 非居民用户分类下拉
export function aaumTaskRank(params){  
    const aaumTaskRank = axios({
        url: '/taskCard/aaumTaskRank',
        method: 'get',
        params
    })
    return aaumTaskRank
}
// 安检情况分析
export function securityAnalysis(params){  
    const securityAnalysis = axios({
        url: '/taskCard/securityAnalysis',
        method: 'get',
        params
    })
    return securityAnalysis
}
// 用户数量扇形图
export function residentCountChart(params){  
    const residentCountChart = axios({
        url: '/taskCard/residentCountChart',
        method: 'get',
        params
    })
    return residentCountChart
}
// 本月设备巡检情况
export function equipmentData(params){  
    const equipmentData = axios({
        url: '/patrolCard/equipmentData',
        method: 'get',
        params
    })
    return equipmentData
}
// 当日管线巡检情况
export function lineData(params){  
    const lineData = axios({
        url: '/patrolCard/lineData',
        method: 'get',
        params
    })
    return lineData
}
// BI大屏的滚动显示 重点调压箱监测数据（张羽）
export function screenImportantBox(params){  
    const screenImportantBox = axios({
        url: '/scada/realTime/screenImportantBox',
        method: 'get',
        params
    })
    return screenImportantBox
}
// BI大屏的滚动显示 泄漏报警情况（张羽）
export function screenLeakageAlarm(params){  
    const screenLeakageAlarm = axios({
        url: '/scada/realTime/screenLeakageAlarm',
        method: 'get',
        params
    })
    return screenLeakageAlarm
}
// 管网概况（赵洋）
export function getscreen1(params){  
    const getscreen1 = axios({
        url: '/screen/screen1',
        method: 'get',
        params
    })
    return getscreen1
}
// 管网压力分布（赵洋）
export function getscreen2(params){  
    const getscreen2 = axios({
        url: '/screen/screen2',
        method: 'get',
        params
    })
    return getscreen2
}