<template>
  <div class="patrolLine" onselectstart="return false;" >
    <!-- <div class="warningT">
      <div class="marquee_box">
        <div id="marquee_left">
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
          <p>
            热烈欢迎各位领导莅临指导工作
          </p>
        </div>
      </div>
    </div> -->
    <div class="box"><p>中联燃气智慧管理平台</p></div>
    <div class="">

    </div>
    <left ref="left" />
    <right ref="right" />
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap" :modeal="'1'"/>  
    </div>
  </div>
</template>
<script>
import left from './biLeft/index'
import right from './biRight/index'
// import subMap from '@/views/mapTool/subMapbi'
import subMap from '@/views/Gis/gisbi'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PatrolHouse',
  components: { left, right ,subMap},
  props: {},
  data: () => ({
    currentArea: null,
    bubbles: null,
    chart: null,
    MenuArray:{}
  }),
  computed: {
    ...mapState('bottom', ['featureMap']),
    ...mapState('dict', ['mainAreaDict'])
  },
  watch: {
    '$store.getters.fullState'(val) {
      console.log(val)
      let rightTool = document.getElementsByClassName('rightTool')[0]
      if (val) {
        rightTool.style.right = '4.22rem'
      } else {
        rightTool.style.right = '0'
        // this.postionVal = -4.52
      }
    },
  },
  destroyed(){
    document.body.classList.remove('black')
  },
  created() {},
  mounted() {
    this.MenuArray = JSON.parse(localStorage.getItem('MenuTree'))
    if(this.MenuArray){
      let exist = false
      this.MenuArray.forEach((item,index)=>{
        if(item.url == 'BI') {
          exist = true
        }
      })
      if(!exist) {
       this.$router.push("./index");
      }
    }
    window.onresize = () => {
      let state = this.$store.getters.onresize
      this.$store.commit('gis/onresizeChange', !state)
    }
    this.$nextTick(()=>{
        let rightTool = document.getElementsByClassName('rightTool')[0]
        rightTool.style.right = '4.22rem'
    })
    
    this.getDatas()
    
  },
  methods: {
    async getDatas(){
      let _this = this
      await _this.$refs.left.getlineData()
      // await _this.$refs.left.getEquipmentData()

      // await _this.$refs.right.getTaskList()
      await _this.$refs.left.setScroll()
    }
  }
}
</script>
<style lang="scss" scoped>
.patrolLine{
  /* height: calc(100vh - 1rem);
  margin-top: 1rem; */
  height: 100vh;
}
.warningT {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0.22rem;
  color: #D42517;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 1rem;
  background: #000;
}
.marquee_box {
  width: 100vw;
  overflow: hidden;
}
#marquee_left {
  /* margin-left: 100vw; */
  animation: infinite 15s left linear;

}
#marquee_left > p {
  font-size: 0.6rem;
  font-weight: bold;
  display: inline-block;
  width: 70vw;
  letter-spacing: 6px;
}
#marquee_left:hover {
  animation-play-state: paused;
}
@keyframes left {
  from {
    transform: translateX(0);
  }
  to {
    /* transform: translateX(-130vw); */
    transform: translateX(calc(-140vw));
  }
}
.box {
  z-index: 5;
  width: 100%;
  text-align: center;
  color: #ffffff;
  line-height: 1rem;
  height: 1.58rem;
  position: fixed;
  background: url('../../assets/br/bg.png') center center;
  background-size: 100% 100%;
  p{
    font-size: 0.47rem;
    background: linear-gradient(0deg, #D8E4FF 0%, #FFFFFF 99.0478515625%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}
.mapRight{
  width: auto;
  margin-left:0;position: relative;overflow: hidden;
  /* height:calc(91vh); */
  height:calc(100vh);

}
.separation {
  position: absolute;
  top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 0.15rem;
  font-weight: 400;
  color: #74beea;
  .separation_first {
    cursor: pointer;
    width: 1.45rem;
    height: 0.32rem;
    background: #172940;
    border: 1px solid #73f7ff;
  }
  .separation_first:first-child {
    border-right: 0;
  }
  .separation_pitch {
    background: rgba(0, 246, 255, 0.16);
    color: #ffffff;
  }
}
.drop-down {
  position: absolute;
  top: 0.8rem;
  right: 0.2rem;
}
</style>
