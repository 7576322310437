<template>
  <div class="bgbox" :class="postionVal == 0.1 ? 'hovers' : 'nohovers'">
    <div class="script entrance" @click="backtrack">驾驶舱</div>
    <div class="simulation" :style="{ left: postionVal==0.1?'-80px':'-105px' }">
      <img v-if="simulation" @click="simulationFun" src="../../../assets/biIndex/m.png" />
      <img v-else  @click="simulationFun" src="../../../assets/biIndex/activem.png" />
     </div>
    <div class="bibox" :style="{ right: postionVal + 'rem' }">
      <div class="rLeft">
        <div class="Putaway" @click="Putaway()">
          <i
            :class="
              postionVal == 0.1 ? 'el-icon-caret-right' : 'el-icon-caret-left'
            "
          ></i>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-item right_item1">
          <div class="chart-title">
            <img :src="title" />
            <div class="titleContext titleContext__between">
              <p>安检情况分析</p>
              <div class="titleContext_button">
                <!-- <div
                  :class="
                    taskStart == 0 ? 'residents noresidents' : 'residents'
                  "
                  @click="stakClick(0)"
                >
                  全部
                </div> -->
                <!-- <div
                  :class="
                    taskStart == 1 ? 'residents noresidents' : 'residents'
                  "
                  @click="stakClick(1)"
                  style="margin-left: 0.05rem"
                >
                  居民
                </div>
                <div
                  :class="
                    taskStart == 2 ? 'residents noresidents' : 'residents'
                  "
                  style="margin-left: 0.05rem"
                  @click="stakClick(2)"
                >
                  非居
                </div> -->
              </div>
            </div>
          </div>
          <div class="rightbar pp1" ref="rightbar1">
            <el-select
            v-model="taskperiodId"
            :placeholder="taskPlaceholder"
            class="selects"
            @change="getSecurityAnalysis"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in taskSpinner"
              :key="item.id"
              :label="item.periodName || item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
            <div class="titleyh backText">
              用户总数(户)
              <span class="fontWeight backText">{{allUers}}</span>
              <img class="pipe_img" src="@/assets/br/bg1.png" />
            </div>
            <el-row :gutter="20">
              <el-col v-for="(item,index) in pollingList" :key="index" :span="8">
                <div class="titleyhItem backText">
                  <span class="fontWeight backText">{{item.val}}</span><br/>
                  {{item.label}}
                  <img class="pipe_img" src="@/assets/br/bg2.png" />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="chart-item right_item2">
          <div class="chart-title">
            <img :src="title" />
            <div class="titleContext titleContext__between">
              <p>泄露报警情况</p>
            </div>
          </div>
          <div class="taskBox rightbar">
            <scroll-table :data="bar1Data" :head="bar1Head" :rate="1000">
              <template #link="{row, col}">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row[col.prop]"
                  placement="top"
                >
                  <span>{{ row.newtime }}</span>
                </el-tooltip>
              </template>
            </scroll-table>
          </div>
        </div>

        <div class="chart-item right_item3">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">重点调压箱监测数据</p>
          </div>
          <div class="keynoteData rightbar">
            <scroll-table :data="bar2Data" :head="bar2Head" :rate="1000">
              <template #link="{row, col}">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row[col.prop]"
                  placement="top"
                >
                  <span>{{ row.newtime }}</span>
                </el-tooltip>
              </template>
            </scroll-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import title from "@/assets/br/chart-title.png";
import {
  arumPeriodPulldown,
  arumTaskRank,
  aaumUserTypePulldown,
  aaumTaskRank,
  securityAnalysis,
  residentCountChart,
  screenImportantBox,
  screenLeakageAlarm
} from "@/RequestPort/bi/index";
import scrollTable from "../scrollTable";
export default {
  name: "MapLine",
  components: { scrollTable },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    simulation:false,
    allUers:23000,
    // 任务列表
    pollingList: [
      {label:'已安检数(户)',val:23000},
      {label:'安检覆盖率',val:'98.20%'},
      {label:'安检合格率',val:'88.20%'},
      {label:'正常入户数(户)',val:22000},
      {label:'到访不遇(户)',val:22000},
      {label:'拒绝入户(户)',val:22000},
    ],
    tableData2: [],
    // 图片
    title,
    // footer,
    postionVal: 0.1,
    tableData: [{}],
    bar1Data: [],
    bar2Data: [],
    bar1Head:[
      { prop: "location", label: "所在位置",width:120 },
      { prop: "valunit", label: "浓度值",align:'center' },
      { prop: "uploadTime", label: "上报时间",width:80,slotName: 'link' }
    ],
    bar2Head:[
      { prop: "address", label: "监测位置" },
      { prop: "val", label: "实时数据",width:110,align:'center' },
      { prop: "time", label: "上报时间",width:80,slotName: 'link'}
    ],
    rightbar3: null,
    // 任务安检周期
    taskSpinner: [],
    taskPlaceholder: "安检周期",
    taskperiodId: "",
    taskStart: 1,
    // 情况
    caseStart: 1,
    caseperiodId: "",
    caseData: {},
    caseSpinner: [],
    eaches: [],
    Interval: {},
    cWidth: true,
    timer1: null,
    screenWidth: null,
    showTip:true,
    eachervalue:'',
    name:'',
    timer:null,
    companyId:''
  }),
  beforeDestroy: function() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  computed: {},
  watch: {
    "$store.getters.fullState"(val) {
      if (val) {
        this.postionVal = 0.1;
      } else {
        this.postionVal = -4.2;
      }
    },
    // "$store.getters.onresize"(val) {
    //   let that = this;
    //   setTimeout(() => {
    //     that.rightbar3.resize();
    //   }, 500);
    // },
    // 'screenWidth' (val, oldVal) {
    //     this.rightbar3.resize()
    // }
  },
  created() {},
  destroyed() {
    this.timer1 && clearInterval(this.timer1)
    this.stopEcharts();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }

    // 安检周期下拉
    // let dates = new Date()
    
    // let hms =
    //   (dates.getHours() < 10 ? '0' + dates.getHours() : dates.getHours()) +
    //   ':' +
    //   (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : dates.getMinutes()) +
    //   ':' +
    //   (dates.getSeconds() < 10 ? '0' + dates.getSeconds() : dates.getSeconds())
    // let hms2 =
    // (dates.getHours() < 10 ? '0' + dates.getHours() : dates.getHours()) +
    //   ':' +
    //   (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : (dates.getMinutes()-5)) +
    //   ':' +
    //   (dates.getSeconds() < 10 ? '00' : dates.getSeconds()-5)
    // let ymd =
    //   dates.getFullYear() +
    //   '-' +
    //   (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
    //   '-' +
    //   (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
    // let endTime = ymd + ' ' + hms
    // let endTime2 = ymd + ' ' + hms2
    this.stakClick(1);
    // let bar1Data = [
    //   {address:'山水芳邻调压箱',val:'5.4',time:endTime},
    //   {address:'山水华城调压箱',val:'0',time:endTime2},
    //   {address:'华辰园调压箱',val:'0',time:endTime2},
    //   {address:'通化县财政局家属楼',val:'0',time:endTime2},
    //   {address:'尚东睿食品有限责任公司',val:'3.4',time:endTime2},
    //   {address:'大亨新村调压箱',val:'0',time:endTime},
    //   {address:'丽景锅炉房调压箱',val:'0',time:endTime},
    //   {address:'益美参业调压箱',val:'0',time:endTime},
    //   {address:'震通参业调庄箱',val:'0.5',time:endTime},
    //   {address:'东盛参业调庄箱',val:'0',time:endTime},
    //   {address:'参宝航参业调庄箱',val:'0',time:endTime}
    // ]
    // this.bar1Data = bar1Data
    // let bar2Data = [
    //   {address:'丽景锅炉房调压箱',val:this.getRandomInt(16,20) ,time:endTime},
    //   {address:'益美参业调压箱',val:this.getRandomInt(16,20) ,time:endTime},
    //   {address:'震通参业调压箱',val:this.getRandomInt(16,20) ,time:endTime},
    //   {address:'东盛参业调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'参宝航参业调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'大象包装调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'路桥集团别墅调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'医药物流调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'东宝实验学校调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
    //   {address:'丽景花园北区调压箱',val:this.getRandomInt(16,20) ,time:endTime},
    // ]
    // this.bar2Data = bar2Data
    this.getList()
    this.setTimer(300000)
    // return 

    // 获取任务列表
  },
  methods: {
    simulationFun(){
      this.simulation=!this.simulation
      if(this.simulation){
        this.$parent.$refs.subMap.openSimulation()
      }else{
        this.$parent.$refs.subMap.closeSimulation()
      }
      
    },
    onmouseover() {
     this.timer1 && clearInterval(this.timer1)
    },
    onmouseout() {
        this.setScroll(true);
    },
    setTimer(time) {
      let that = this
      if (that.timer) {
        clearInterval(that.timer)
      }
      that.timer = setInterval(function() {
        that.getList()
      }, time)
    },
    getList(){
      // console.log('刷新数据')
      // let dates = new Date()
      
      // let hms =
      //   (dates.getHours() < 10 ? '0' + dates.getHours() : dates.getHours()) +
      //   ':' +
      //   (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : dates.getMinutes()) +
      //   ':' +
      //   (dates.getSeconds() < 10 ? '0' + dates.getSeconds() : dates.getSeconds())
      // let hms2 =
      // (dates.getHours() < 10 ? '0' + dates.getHours() : dates.getHours()) +
      //   ':' +
      //   (dates.getMinutes() < 10 ? '0' + dates.getMinutes() : (dates.getMinutes()-5)) +
      //   ':' +
      //   (dates.getSeconds() < 10 ? '00' : dates.getSeconds()-5)
      // let ymd =
      //   dates.getFullYear() +
      //   '-' +
      //   (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
      //   '-' +
      //   (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
      // let endTime = ymd + ' ' + hms
      // let endTime2 = ymd + ' ' + hms2
      // let bar1Data = [
      //   {address:'山水芳邻调压箱',val:'5.4',time:endTime},
      //   {address:'山水华城调压箱',val:'0',time:endTime2},
      //   {address:'华辰园调压箱',val:'0',time:endTime2},
      //   {address:'通化县财政局家属楼',val:'0',time:endTime2},
      //   {address:'尚东睿食品有限责任公司',val:'3.4',time:endTime2},
      //   {address:'大亨新村调压箱',val:'0',time:endTime},
      //   {address:'丽景锅炉房调压箱',val:'0',time:endTime},
      //   {address:'益美参业调压箱',val:'0',time:endTime},
      //   {address:'震通参业调庄箱',val:'0.5',time:endTime},
      //   {address:'东盛参业调庄箱',val:'0',time:endTime},
      //   {address:'参宝航参业调庄箱',val:'0',time:endTime}
      // ]
      // this.bar1Data = bar1Data
      // let bar2Data = [
      //   {address:'丽景锅炉房调压箱',val:this.getRandomInt(16,20) ,time:endTime},
      //   {address:'益美参业调压箱',val:this.getRandomInt(16,20) ,time:endTime},
      //   {address:'震通参业调压箱',val:this.getRandomInt(16,20) ,time:endTime},
      //   {address:'东盛参业调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'参宝航参业调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'大象包装调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'路桥集团别墅调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'医药物流调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'东宝实验学校调压箱',val:this.getRandomInt(16,20) ,time:endTime2},
      //   {address:'丽景花园北区调压箱',val:this.getRandomInt(16,20) ,time:endTime},
      // ]
      // this.bar2Data = bar2Data
      this.getEmphasis()
      this.getscreenLeakageAlarm()
    },
    /**
     * 重点调压箱检测数据
     */
    getEmphasis(){
      screenImportantBox().then(res => {
        if(res.code == 200) {
          let bar2Datafake = res.data
          let data = []
          bar2Datafake.forEach(el => {
            el.pointList.forEach(item => {
              item.newTime = item.uploadTime.slice(11)
              data.push({
                address:el.location,
                val:item.val+' '+item.unit,
                time:item.uploadTime,
                newtime:item.newTime
              })
            })
          })
          this.bar2Data = data
        }
      })
    },
    /**
     * BI大屏的滚动显示 泄漏报警情况
     */
    getscreenLeakageAlarm(){
      screenLeakageAlarm().then(res => {
        if(res.code == 200) {
          let bar1Data = res.data
          bar1Data.forEach(el => {
            el.newtime = el.uploadTime.slice(11)
            el.valunit = el.val+' '+el.unit
          })
          this.bar1Data = bar1Data
        }
      })
    },
    getRandomInt(min, max) {
      //min = Math.ceil(min); // 向上取整最小值
      //max = Math.floor(max); // 向下取整最大值
      return (Math.floor(Math.random() * (max - min + 1)) + min)/100; // 返回[min, max]区间内的随机整数
    },

    // 切换任务情况的居民非居民
    async stakClick(val) {
      this.taskStart = val;
      // let allData =[]
      // let pollingList=[]
      // this.companyId=localStorage.getItem('zlCompanyId')
      // 1通化 非1 四平
      // if(this.companyId != 1) {
      //  allData = [4430,4351,79]
      // pollingList = [
      //   [{label:'已安检数(户)',val:4430},
      //   {label:'安检覆盖率',val:'81.63%'},
      //   {label:'安检合格率',val:'100%'},
      //   {label:'正常入户数(户)',val:3616},
      //   {label:'到访不遇(户)',val:814},
      //   {label:'拒绝入户(户)',val:0}],
      //   [{label:'已安检数(户)',val:3548},
      //   {label:'安检覆盖率',val:'81.54%'},
      //   {label:'安检合格率',val:'100%'},
      //   {label:'正常入户数(户)',val:3548},
      //   {label:'到访不遇(户)',val:803},
      //   {label:'拒绝入户(户)',val:0}],
      //   [{label:'已安检数(户)',val:79},
      //   {label:'安检覆盖率',val:'86.07%'},
      //   {label:'安检合格率',val:'100%'},
      //   {label:'正常入户数(户)',val:68},
      //   {label:'到访不遇(户)',val:11},
      //   {label:'拒绝入户(户)',val:0}],
      // ]
      // } else {
      //    allData = [25708,25605,103]
      // pollingList = [
      //   [{label:'已安检数(户)',val:17223},
      //   {label:'安检覆盖率',val:'100%'},
      //   {label:'安检合格率',val:'78.80%'},
      //   {label:'正常入户数(户)',val:17223},
      //   {label:'到访不遇(户)',val:7724},
      //   {label:'拒绝入户(户)',val:761}],
      //   [{label:'已安检数(户)',val:17120},
      //   {label:'安检覆盖率',val:'100%'},
      //   {label:'安检合格率',val:'59.39%'},
      //   {label:'正常入户数(户)',val:17120},
      //   {label:'到访不遇(户)',val:7724},
      //   {label:'拒绝入户(户)',val:761}],
      //   [{label:'已安检数(户)',val:103},
      //   {label:'安检覆盖率',val:'100%'},
      //   {label:'安检合格率',val:'19.41%'},
      //   {label:'正常入户数(户)',val:103},
      //   {label:'到访不遇(户)',val:0},
      //   {label:'拒绝入户(户)',val:0}],
      // ]

      // }
      // this.allUers = allData[val]
      // this.pollingList = pollingList[val]
      if (val == 2) {
        this.taskPlaceholder = "用户分类";
        await this.getaAumUserTypePulldown("taskSpinner");
      } else {
        this.taskPlaceholder = "安检周期";
        await this.getArumPeriodPulldown("taskSpinner");
      }
      this.taskSpinner.length > 0 &&
        (this.taskperiodId = this.taskSpinner[0].id);
      await this.caseClick(this.taskStart);
    },
    // 居民安检周期下拉
    async getArumPeriodPulldown(val) {
      let e = await arumPeriodPulldown();
      if (e.code == 200) {
        this[val] = e.data;
      }
    },
    // 非居民用户分类下拉
    async getaAumUserTypePulldown(val) {
      let e = await aaumUserTypePulldown();
      if (e.code == 200) {
        this[val] = e.data;
      }
    },
    // 获取任务列表
    // async getTaskList(val) {
      // this.timer1 && clearInterval(this.timer1)
      // 居民
      // if (this.taskStart == 1) {
      //   let data = {
      //     periodId: val,
      //   }
      //   await arumTaskRank(data).then((e) => {
      //     if (e.code == 200) {
      //       this.pollingList = e.data;
      //     }
      //   });
      // } else {
      //   // 非居民
      //   let data = {
      //     userTypeId: val,
      //   }
      //   await aaumTaskRank(data).then((e) => {
      //     if (e.code == 200) {
      //       this.pollingList = e.data;
      //     }
      //   });
      // }
      // this.pollingList.forEach((el, index) => {
      //   el.index = index;
      // });
      // await this.setScroll();
    // },
    // 情况选择居民非居民
    async caseClick(val) {
      this.caseStart = val;
      // if (val == 2) {
      //   await this.getaAumUserTypePulldown("caseSpinner");
      // } else {
      //   await this.getArumPeriodPulldown("caseSpinner");
      // }
      // this.caseSpinner.length > 0 &&
      //   (this.caseperiodId = this.caseSpinner[0].id);
      await this.getSecurityAnalysis(this.taskperiodId);
    },
    // 情况分析数据
    getSecurityAnalysis(val) {
      let data = {
        paramId: val,
        type: this.caseStart,
      }
      securityAnalysis(data).then((e) => {
        if (e.code == 200) {
          this.caseData = e.data;
           this.allUers = this.caseData.allResidentCount
          this.pollingList  = [
            {label:'已安检数(户)',val:this.caseData.checkResidentCount},
            {label:'安检覆盖率',val:this.caseData.coverageRate},
            {label:'安检合格率',val:this.caseData.passRate},
            {label:'正常入户数(户)',val:this.caseData.normalEntryCount},
            {label:'到访不遇(户)',val:this.caseData.noMeetCount},
            {label:'拒绝入户(户)',val:this.caseData.refuseEntryCount},
          ]
        }
      });
    },
    backtrack() {
      //  this.$router.go(-1)
      this.$router.push("./index");
    },
    funMap() {
      residentCountChart().then((e) => {
        if (e.code == 200) {
          this.eaches = e.data;
          const color = ["#61D95B", "#FFAC26", "#E92E3B", "#7777F8", "#15D0FF"];
          
          let option = {
            color,
            title: {
              //圆环中间内容
              text: "",
              subtext: "",
              left: "29%",
              top: "40%",
              textAlign: "center",
              textStyle: {
                color: "#F0F2F5",
                fontSize: 16,
                // align: 'center'
              },
              subtextStyle: {
                fontSize: 12,
                fontWeight: "700",
                // align: 'center',
                color: "#F0F2F5",
              },
            },
            legend: {
              type: "scroll",
              orient: "vertical",
              x: "55%",
              y: "center",
              itemWidth: 12,
              itemHeight: 12,
              itemGap: 14,
              textStyle: {
                fontSize: 12,
                color: "#ffffff",
              },
              formatter: function (name) {
                let res = e.data.filter((v) => v.name === name);
                let str = "";
                str = res[0].name + " " + res[0].perc;
                return str;
              },
              //   formatter: function (name) {
              //                 // console.log(data, 'data')
              //                 let total = 0
              //                 let tarValue
              //                 for (let i = 0; i < e.data.length; i++) {
              //                     total += e.data[i].value
              //                     if (e.data[i].name == name) {
              //                     tarValue = e.data[i].value
              //                     }
              //                 }
              //                 //计算出百分比
              //                 let p = ((tarValue / total) * 100).toFixed() + '%'
              //                 return `${name}  ${p}`
              //                 //name是名称，v是数值
              //             }
            },

            tooltip: {
              trigger: "item",
              axisPointer: {
                type: "shadow",
              },
              hideDelay: 100,   
              formatter: "{b} : {c} <br/>{d}%", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
            },
            grid: {
              left: "5%",
              right: "5%",
              bottom: "3%",
              top: "10%",
              containLabel: true,
            },
            series: [
              {
                type: "pie",
                center: ["30%", "50%"],
                radius: ["50%", "53%"],
                data: e.data,
                startAngle: 180,
                label: {
                  show: false,
                },
              },
              {
                type: "pie",
                center: ["30%", "50%"],
                radius: ["50%", "60%"],
                data: e.data,
                startAngle: 180,
                label: {
                  normal: {
                    position: "inner",
                    show: false,
                  },
                },
              },
            ],
          }
          this.stopEcharts()
          this.rightbar3.setOption(option, true);
          this.lunboEcharts(this.rightbar3, option, "PieGraph");
        }
      });
    },

    // lunboEcharts(echartsId, dataOption, echartStart) {
    //   var currentIndex = 0;
    //   let that = this
    //   var dataLen = dataOption.series[0].data.length;
    //  setTimeout(() => {
    //   this.Interval[Date.now()] = setInterval(() => {
        
    //     echartsId.dispatchAction({
    //       type: "downplay",
    //       seriesIndex: 0,
    //      // dataIndex: currentIndex //% dataLen,
    //     });
    //     // currentIndex = (currentIndex + 1) % dataLen;
    //       echartsId.dispatchAction({
    //         type: "highlight",
    //         seriesIndex: 0,
    //         dataIndex: currentIndex,
    //       });
    //       echartsId.dispatchAction({
    //           type: "showTip",
    //           seriesIndex: 0,
    //           dataIndex: currentIndex,
    //         });
            
            
    //       // this.$nextTick(()=>{
    //       //   echartsId.dispatchAction({
    //       //     type: "showTip",
    //       //     seriesIndex: 0,
    //       //     dataIndex: currentIndex,
    //       //   });
    //       // })
          
    //     if (
    //       echartStart == "PieGraph" &&
    //       !!dataOption.series[0].data[currentIndex].name
    //     ) {
    //       if( that.screenWidth > 1200 ) {
            
    //        this.eachervalue=dataOption.series[0].data[currentIndex].value
    //        this.name=dataOption.series[0].data[currentIndex].name
    //       // echartsId.setOption({
    //       //   title: {
    //       //     text: dataOption.series[0].data[currentIndex].value,
    //       //     subtext: dataOption.series[0].data[currentIndex].name,
    //       //     textStyle: {
    //       //       color: "#F0F2F5",
    //       //       fontSize: 24,
    //       //     },
    //       //     subtextStyle: {
    //       //       fontSize: 14,
    //       //       fontWeight: "700",
    //       //       color: "#F0F2F5",
    //       //     },
    //       //   },
    //       // });
    //       }
    //     }
    //     currentIndex=currentIndex+1
    //     if(currentIndex>=dataLen){
    //           currentIndex=0
    //         }
    //   }, 1500);
    //  },500)
    // },
    stopEcharts() {
      Object.values(this.Interval).forEach((k) => clearTimeout(k));
    },
    Putaway() {
      let state = this.$store.getters.fullState;
      this.$store.commit("gis/FullStateChange", !state);
    },
    setScroll(val) {
      if (this.pollingList.length < 6) return (this.tableData2 = this.pollingList);
      !val && (this.tableData2 = [...this.pollingList, ...this.pollingList]);
      let _this = this;
      let body = this.$refs.rightbar1;
      setTimeout(() => {
        // console.log(_this.$refs)
        _this.timer1 && clearInterval(_this.timer1)
        let winHeight = body.clientHeight;
        let max = parseInt(winHeight / 50);
        let rowHeight = parseInt(winHeight / max);
        let els = body.getElementsByClassName("polling");
        for (let el of els) {
          el.style = `height: ${rowHeight}px !important; box-sizing: border-box;`;
        }
        // body.scrollTop = 0;

        _this.timer1 = setInterval(async () => {
          if (_this.active < _this.pollingList.length) {
            _this.sj(rowHeight, body);
          } else {
            body.scrollTop = 0;
            _this.active = 0;
            _this.sj(rowHeight, body);
          }
        }, 1500);
      }, 200);
    },
    async sj(rowHeight, body) {
      let _this = this;
      if (this.active < this.pollingList.length) {
        this.active += 1;
        for (let i = 0; i <= rowHeight; i++) {
          await _this.wait(0.5 / rowHeight);
          body.scrollTop = Math.ceil(body.scrollTop + 1);
          if (body.scrollTop >= this.active * rowHeight) {
            body.scrollTop = parseInt(this.active * rowHeight);
            break;
          }
        }
      } else {
        body.scrollTop = 0;
        this.active = 0;
      }
    },
    wait(time = 0) {
      return new Promise((res, rej) => setTimeout(() => res(), time * 1000));
    },
  },
};
</script>

<style lang="scss" scoped>
.simulation{position: absolute;top:0.99rem;cursor: pointer;}
.selects {
  width: 3.86rem;
  height: 0.2rem;
  border: 1px solid rgba(14, 139, 255, 0.5);
  margin: 0rem auto;
}
.script {
  position: fixed;
  top: .1rem;
  right: .5rem;
  // left: 0.43rem;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6eb4ff;
  cursor: pointer;
}
.entrance {
  width: 72px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  /* background: url("../../../assets/biIndex/entrance1.png") top center no-repeat; */
  /* background-size: 100% 100%; */
  font-size: 16px;
  /* font-family: Microsoft YaHei;
  font-weight: bold;
  font-style: italic; */
  color: #ffff;
  cursor: pointer;
  background: linear-gradient(0deg, #0072D2 0%, #3D98F6 100%);
  border-radius: 7px;
}
/* .entrance:hover {
  background: url("../../../assets/biIndex/entrance.png") top center no-repeat;
} */
.backText{
  color: #EDFBFF;
}
.pipe_img{
  width: 100%;
  height: 100%;
  margin-top: -0.1rem;
}
.titleyhItem{
  font-size: 0.12rem;
  font-weight: 400;
  color:#EDFBFF;
  .fontWeight{
    font-weight: bold;
    font-size: 0.24rem;
  }
}
.titleyh{
  font-size: 0.16rem;
  font-weight: 400;
  color:#EDFBFF;
  .fontWeight{
    font-weight: bold;
    font-size: 0.24rem;
  }
}
.keynoteData{
  height: 2.24rem;
}
.rightbar {
  flex: 1;
  width: 100%;
  background-color: rgba(#0f2545,  .96);
}
.bgbox {
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 4.8rem;
  right: 0;
  /* background: linear-gradient(-90deg, #05193d 60%, rgba(5, 25, 61, 0) 100%); */
}
/* .hovers { */
  /* background: linear-gradient(-90deg, #05193d 60%, rgba(5, 25, 61, 0) 100%); */
/* } */
.nohovers {
  width: 0.1rem;
  background: none;
}
.bibox {
  transition: right 0.5s;
  position: fixed;
  z-index: 9;
  top: 1rem;
  right: 0;
  height: calc(100vh - 1.2rem);
  text-align: center;
  color: var(--title-text-color);
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(90deg, rgba(15, 37, 69, 0) 0%, #0f2545 60%);
}
.chart-box {
  width: 4.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-item {
  width: 100%;
  height: 28%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(47, 154, 255, 0.1);
  .chart-title {
    color: #fff;
    text-align: left;
    position: relative;
    height: 35px;
    p,
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    p {
      padding-left: 0.4rem;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      /* font-style: italic; */
      // line-height: 0.35rem;
      line-height: 35px;
      text-shadow: 3px 3px 3px rgba(0, 9, 17, 0.52);
    }
  }
  .chart-footer {
    width: 100%;
    overflow: hidden;
  }
}
.right_item1 {
  height: 37%;
}
.right_item2 {
  height: 28%;
}
.right_item3 {
  height: 32%;
}
.rLeft {
  position: relative;
}
.Putaway {
  background: #182c3e;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.46rem;
}
.polling {
  padding: 0.1rem 0.2rem;
  height: 0.39rem;
  .polling_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .person_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      .person_name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00f6ff;

        text-align: left;
        span {
          display: block;
          overflow: hidden; //超出隐藏
          white-space: nowrap; //不折行
          text-overflow: ellipsis;
          width: 2.8rem;
          font-size: 0.16rem;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .person_num {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .schedule_box_img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .person_img {
      width: 0.09rem;
      height: 0.1rem;
      margin-right: 0.13rem;
    }
  }
  .schedule {
    flex: 1;
    height: 0.16rem;
    line-height: 0.16rem;
    border: 1px solid rgba(223, 254, 255, 0.15);
    .schedule_box {
      width: 3.62rem;
      height: 0.04rem;
      background: rgba(94, 167, 255, 0.3);
      border-radius: 0.02rem;
      margin: 0.06rem auto;
      position: relative;
      .schedule_img {
        position: absolute;
        top: -0.06rem;
        width: 0.2rem;
        height: 0.16rem;
        transition-duration: 1s;
      }
    }
    .schedule_fill {
      width: 1rem;
      height: 0.04rem;
      background: linear-gradient(
        90deg,
        rgba(0, 246, 255, 0.85) 80%,#6EF2F9, #c5fdff 
      );
      transition-duration: 1s;
      border-radius: 2px;
    }
    .one {
      width: 0rem !important;
    }
    .two {
      left: -0.1rem !important;
    }
  }
}
.pp1 {
  overflow-y: auto;
  height: 2.7rem;
  padding: 0.1rem 0.3rem;
  width: calc(100% - 0.6rem);
}
::-webkit-scrollbar {
  width: 0px;
}
.titleContext__between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleContext_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    // top: 0.04rem;
    right: 0.1rem;
    top: 50%;
    margin-top: -0.15rem;
    .residents {
      width: 0.56rem;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      background: url("../../../assets/br/resident.png") no-repeat;
      background-size: 100% 100%;
      font-size: 0.14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #63b3ff;
      cursor: pointer;
    }
    .noresidents {
      color: #ffffff;
      background: url("../../../assets/br/bgselect.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.selects {
  width: 3.86rem;
  height: 0.2rem;
  border: 1px solid rgba(14, 139, 255, 0.5);
  margin: 0.13rem auto;
}
::v-deep .el-select,
::v-deep .el-input,
::v-deep .el-input__inner {
  background-color: rgba(0, 81, 157, 0.1);
  color: rgba(255, 255, 255, 1);
  border: none; // 去掉边框
  // border-color: #XXXXXX // 默认边框的颜色
  text-align: left;
  border-radius: 4px;
  line-height: 0.2rem;
}
::v-deep .el-input__inner {
  width: 3.86rem;
  height: 0.34rem;

  border: 1px solid rgba(14, 139, 255, 0.5);
}
::v-deep .el-input__icon {
  line-height: 0.34rem;
}
::v-deep .el-input__inner:hover {
  border: 1px solid rgba(14, 139, 255, 0.5);
}
::v-deep .el-select-dropdown {
  background: #0a2f5a;
  // border:0px;
  border-top: 3px;
  border: 1px solid rgba(14, 139, 255, 0.5);
  // top: 125px !important;
  // border-radius: 0px;
}
::v-deep .el-select-dropdown__item {
  background-color: rgba(0, 81, 157, 0.1);

  color: #fff;
}
::v-deep .el-popper {
  margin-top: 4px;
}
::v-deep .el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none;
  // border-bottom-color:rgba(0, 81, 157, 0.1) !important;
}
::v-deep .el-select-dropdown__item.hover,
::v-deep .el-select-dropdown__item:hover {
  // color:rgb(21,43,94);
  background: #042953;
}
.taskBox {
  height: calc(100% - 0.35rem);
}
.eacher_box{
  position: absolute;
  top: 48%;
    left: 18%;
    text-align: center;
    min-width: 1rem;
  .eacher_num{
    color: #F0F2F5;
    font-size: 0.24rem;
  }
  .eacher_name{
    font-size: 0.14rem;
    font-weight: 700;
    color: #F0F2F5
  }
}
</style>
