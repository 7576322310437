<template>
  <div class="bgbox" :class="postionVal == 0.1 ? 'hovers' : 'nohovers'">
    <!-- <div class="script entrance" @click="backtrack">驾驶舱</div> -->
    <div class="bibox" :style="{ left: postionVal + 'rem' }">
      <div class="rLeft">
        <div class="Putaway" @click="Putaway()">
          <i
            :class="
              postionVal == 0.1 ? 'el-icon-caret-left' : 'el-icon-caret-right'
            "
          ></i>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-item pipe">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">管网概况</p>
          </div>
          <div class="rightbar pipe_box">
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/network.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{ general.pipeLength }}</div>
                <div class="pipeBox_text">管网总长(KM)</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/network2.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{ general.station2Num }}</div>
                <div class="pipeBox_text">调压箱(柜)</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/network3.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{ general.valveNum }}</div>
                <div class="pipeBox_text">阀门井</div>
              </div>
            </div>
            <div class="pipeBox">
              <div class="pipeBox_img">
                <img class="pipe_img" src="@/assets/br/network4.png" />
              </div>
              <div>
                <div class="pipeBox_number">{{ general.station1Num }}</div>
                <div class="pipeBox_text">供气站</div>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-item pipe2">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">管网压力分布</p>
          </div>
          <div class="rightbar pp1" ref="leftbar3">

          </div>
        </div>

        <div class="chart-item pipe3">
          <div class="chart-title">
            <img :src="title" />
            <p class="titleContext titleContext__between">本月巡检情况</p>
          </div>
          <div class="rightbar">
            <scroll-table :data="bar1Data" :head="bar1Head" :rate="1000">
              <template #link="{ row, col }">
                <div
                  class="link-block"
                  :style="{
                    color:
                      col.prop == 'doneNum'
                        ? '#32FFAF'
                        : col.prop == 'exceptionNum'
                        ? '#FF2C2C'
                        : '#ffffff',
                  }"
                >
                  {{ row[col.prop] }}
                </div>
              </template>
            </scroll-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import title from "@/assets/br/chart-title.png";
import scrollTable from "../scrollTable";
import { equipmentData, lineData,getscreen1,getscreen2 } from "@/RequestPort/bi/index";
import { getPipeOverview } from "@/views/Gis/apis/gis";
import "echarts-gl";
export default {
  name: "MapLine",
  components: { scrollTable },
  props: {
    layerSelect: [],
    type: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    title,
    postionVal: 0.1,
    // 评分周期视图
    leftbar1: null,
    leftbar2: null,
    leftbar3: null,
    bar1Data: [],
    bar1Head: [
      { prop: "equipmentTypeName", label: "巡线类型",width:150 },
      { prop: "patrolNum", label: "巡检点数" },
      { prop: "exceptionNum", label: "异常数", slotName: 'link'  },
      { prop: "handleNum", label: "已处理数", slotName: 'link'  },
    ],
    pollingList: [],
    tableData2: [],
    general: {
      pipeLength: "0.00",
      station1Num: "0",
      station2Num: "0",
      valveNum: "0"
    },
    active: 0,
    timer2: null,
    cWidth: true,
    companyId:''
  }),
  computed: {
   
  },
  watch: {
    "$store.getters.fullState"(val) {
      if (val) {
        this.postionVal = 0.1;
      } else {
        this.postionVal = -4.2;
      }
    },
  },
  destroyed() {
    clearInterval(this.timer2);
  },
  mounted() {
    this.leftbar3 = this.$echarts.init(this.$refs.leftbar3);
    this.drawPipe()
    // this.setScroll()
    // this.getPipeOverviews();
    this.getDatas()
    // setTimeout( () => this.cWidth = false, 1500)
    // this.getEquipmentData();
    // this.getlineData();
  },
  methods: {
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
        // 计算
        const midRatio = (startRatio + endRatio) / 2;
        const startRadian = startRatio * Math.PI * 2;
        const endRadian = endRatio * Math.PI * 2;
        const midRadian = midRatio * Math.PI * 2;
        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
            isSelected = false;
        }
        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = 1;
        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        const hoverRate = isHovered ? 1.05 : 1;
        // 返回曲面参数方程
        return {
            u: {
                min: -Math.PI,
                max: Math.PI * 3,
                step: Math.PI / 32,
            },
            v: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },
            x: function (u, v) {
                if (u < startRadian) {
                    return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
            },
            y: function (u, v) {
                if (u < startRadian) {
                    return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
            },
            z: function (u, v) {
                if (u < -Math.PI * 0.5) {
                    return Math.sin(u);
                }
                if (u > Math.PI * 2.5) {
                    return Math.sin(u) * h * 0.1;
                }
                return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
            },
        };
    },
    /**
     * 绘制3d图
     * @param pieData 总数据
     * @param internalDiameterRatio:透明的空心占比
     * @param distance 视角到主体的距离
     * @param alpha 旋转角度
     * @param pieHeight 立体的高度
     * @param opacity 饼或者环的透明度
     */
    getPie3D(pieData, internalDiameterRatio, distance, alpha, pieHeight, opacity = 1) {
        const series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        const legendData = [];
        const k =
            typeof internalDiameterRatio !== 'undefined'
                ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
                : 1 / 3;
        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i += 1) {
            sumValue += pieData[i].value;
            const seriesItem = {
                name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                type: 'surface',
                parametric: true,
                wireframe: {
                    show: false,
                },
                pieData: pieData[i],
                pieStatus: {
                    selected: false,
                    hovered: false,
                    k: k,
                },
            };
            if (typeof pieData[i].itemStyle !== 'undefined') {
                const itemStyle = {};
                if (typeof pieData[i].itemStyle.color !== 'undefined') {
                    itemStyle.color = pieData[i].itemStyle.color;
                }
                if (typeof pieData[i].itemStyle.opacity !== 'undefined') {
                    itemStyle.opacity = pieData[i].itemStyle.opacity;
                }
                seriesItem.itemStyle = itemStyle;
            }
            series.push(seriesItem);
        }
        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i += 1) {
            endValue = startValue + series[i].pieData.value;
            series[i].pieData.startRatio = startValue / sumValue;
            series[i].pieData.endRatio = endValue / sumValue;
            // console.log(series[i].pieData.startRatio,
            //     series[i].pieData.endRatio,
            //     false,  
            //     false,
            //     k,
            //     series[i].pieData.value)
            series[i].parametricEquation = this.getParametricEquation(
                series[i].pieData.startRatio,
                series[i].pieData.endRatio,
                false,
                false,
                k,
                series[i].pieData.value
            );
            startValue = endValue;
            legendData.push(series[i].name);
        }
        return series;
    },
    /**
     * 管网概况
     */
    getgeneral(){
      getscreen1().then(res=> {
        if(res.code == 200) {
          this.general= res.data
        }
      })
    },
    /**
     * 管网压力分布
     */
    async getscreen(){
      let res = await getscreen2()
      // res.data = [
      //   { name: '高压',value: 37.639},
      //   { name: '次高压',value: 12.5915},
      //   {name: '低压庭院', value: 11.83239}
      // ]
      let colors = {高压:'#Ff0000',高压A:'#Ff0000',高压B:'#Ffaaaa',次高压A:'#F88B5A',次高压B:'#F88B05',低压庭院:'#00A8FF',中压A:'#EFC18B',中压B:'#EFC06B',低压:'#0093FF'}
       res.data.forEach(el => {
        el.value = Number(el.value)
        el.itemStyle = {color:colors[el.name]}
      });
      let optionsData = res.data
      return optionsData
    },
    async drawPipe(){
      let optionsData  = []
      this.companyId=localStorage.getItem('zlCompanyId')
      // 管网概况
      await this.getgeneral()
      // 管网压力分布
      await this.getscreen().then(res => {
        optionsData = res
      })
      // 1通化 非1 四平
      // if(this.companyId != 1) {
      //    optionsData = [
      //   {
      //         name: '高压',
      //         value: 37.639,
      //         itemStyle: {
      //             color: '#Ff0000',
      //             // opacity: 1,
      //         },
      //     },
      //     {
      //         name: '次高压',
      //         value: 12.5915,
      //         itemStyle: {
      //             color: '#F88B5A',
      //             // opacity: 1,
      //         },
      //     },
      //     {
      //         name: '低压庭院',
      //         value: 11.83239,
      //         itemStyle: {
      //             color: '#00A8FF',
      //             // opacity: 1,
      //         },
      //     },
      //     {
      //         name: '中压',
      //         value: 16.98801,
      //         itemStyle: {
      //             color: '#EFC18B',
      //             // opacity: 1,
      //         },
      //     },
      // ];
      //   } else {
      //     this.general= {
      //       totalLength:'83.838',
      //       stationKindValue:"88",
      //       pipeFittingValue:'71',
      //       stationCount:'1'
      //     },

      // optionsData = [
      //     {
      //         name: '次高压',
      //         value: 12.5,
      //         itemStyle: {
      //             color: '#F88B5A',
      //             // opacity: 1,
      //         },
      //     },
      //     {
      //         name: '低压庭院',
      //         value: 46.856,
      //         itemStyle: {
      //             color: '#00A8FF',
      //             // opacity: 1,
      //         },
      //     },
      //     {
      //         name: '中压',
      //         value: 24.482,
      //         itemStyle: {
      //             color: '#EFC18B',
      //             // opacity: 1,
      //         },
      //     },
      // ];
      //   }
      
      const series = this.getPie3D(optionsData, 0.8, 240, 28, 26, 0.5);
      series.push({
          name: 'pie2d',
          type: 'pie',
          label: {
              opacity: 1,
              fontSize: 13,
              lineHeight: 20,
          },
          labelLine: {
              length: 20,
              length2: 20,
          },
          startAngle: -10, //起始角度，支持范围[0, 360]。
          clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
          radius: ['20%', '50%'],
          center: ['50%', '50%'],
          data: optionsData,
          itemStyle: {
              opacity: 0,
          },
          labelLayout: {
            hideOverlap: false
          }
      });

      let option = {
          // legend: {
          //     tooltip: {
          //         show: true,
          //     },
          //     data: ['aa', 'bb', 'cc'],
          //     bottom: '10%',
          //     textStyle: {
          //         color: '#fff',
          //         fontSize: 12,
          //     },
          // },
          // tooltip: {
          //     formatter: (params) => {
          //         if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                    
          //             let bfb = (
          //                 (option.series[params.seriesIndex].pieData.endRatio -
          //                     option.series[params.seriesIndex].pieData.startRatio) *
          //                 100
          //             ).toFixed(2);
          //             return (
          //                 `<span style="color:red">${params.seriesName}</span><br/>` 
          //                 +
          //                 // `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span><br/>` +
          //                 `${bfb}%`
          //             );
          //         }
          //     },
          // },
          labelLine: {
              show: true,
              lineStyle: {
                  color: '#7BC0CB',
              },
          },
          label: {
              show: true,
              position: 'outer',
              alignTo:'labelLine',
              formatter: [
                  '{b|{b}}',
                  '{c|{c}km}',
                  '{d|{d}%}',
              ].join('\n'),
              rich: {
                b: {
                  color: '#41A6FC',
                  fontSize: 12
                },
                c: {
                  color: '#fff',
                  fontSize: 12
                },
                d: {
                  color: '#fff',
                  fontSize: 12
                }
              }
          },
          xAxis3D: {
              min: -1,
              max: 1,
          },
          yAxis3D: {
              min: -1,
              max: 1,
          },
          zAxis3D: {
              min: -1,
              max: 1,
          },
          grid3D: {
              show: false,
              boxHeight: 20, // 三维笛卡尔坐标系在三维场景中的高度
              viewControl: {
                  alpha: 30,
                  beta: 40,
                  distance: 500, //调整视角到主体的距离，类似调整zoom
                  rotateSensitivity: 0, // 设置为0无法旋转
                  zoomSensitivity: 0, // 设置为0无法缩放
                  panSensitivity: 0, // 设置为0无法平移
                  autoRotate: false, // 自动旋转
              },
          },
          series: series,
      };
      this.leftbar3.setOption(option,true)
    },
    onmouseover(){
      this.timer2 && clearInterval(this.timer2)
    },
    onmouseout(){
       this.setScroll("","",true)
    },
    backtrack() {
      //  this.$router.go(-1)
      this.$router.push("./index");
    },
    Putaway() {
      let state = this.$store.getters.fullState;
      this.$store.commit("gis/FullStateChange", !state);
    },
    getPipeOverviews() {
      getPipeOverview().then((res) => {
        if (res.code == 200) {
          this.general.totalLength = (res.data.pipeLineLengthVO.totalLength/1000).toFixed(2);
          // console.log(Number(res.data.stationCountVOList[1].stationKindList[1].value) , Number(res.data.stationCountVOList[1].stationKindList[2].value))
          this.general.stationKindValue = Number(res.data.stationCountVOList[1].stationKindList[1].value) + Number(res.data.stationCountVOList[1].stationKindList[2].value)
          this.general.pipeFittingValue = res.data.pipeFittingCountVO.pipeFittingList[0].value
          this.general.stationCount = res.data.stationCountVOList[0].stationCount
        }
      });
    },
    async getDatas(){
      let _this = this
      await _this.getlineData()
      await _this.getEquipmentData()
      await _this.setScroll(this.lineDatas,"pollingList",false)
    },
    async getlineData() {
      let data = {
        current: 1,
        size: 10000,
      };
      let that = this
      let res = await lineData(data)
      if (res.code == 200) {
        // res.data.list = [...res.data.list, ...res.data.list, ...res.data.list,...res.data.list]
        // this.pollingList = res.data.list
        // console.log(res.data)
        // that.$nextTick(() => {
          this.lineDatas = res.data
          // that.setScroll(res.data, "pollingList");
        // })
      }
    },
    async getEquipmentData() {
      let data = {
        current: 1,
        size: 10000,
      };
      let res = await equipmentData(data)
        if (res.code == 200) {
          // this.bar1Data = [...res.data.list, ...res.data.list, ...res.data.list,...res.data.list]
          this.bar1Data = res.data.list;
          // this.setScroll(res.data, "tableData2");
        }
    },
    setScroll(val, name,num) { 
      try {
        // if(!num){
        //   val = this.lineDatas
        //   name = "pollingList"
        // } 
        if (this.lineDatas.list.length < 6) return (this.pollingList = this.lineDatas.list);
        !num && (this.pollingList = [...this.lineDatas.list,...this.lineDatas.list])
        let _this = this;
        let body = this.$refs.leftbar3
        // console.log(body, '---------------');
        setTimeout(() => {
          _this.timer2 && clearInterval(_this.timer2)
          let winHeight = body.clientHeight
          let max = parseInt(winHeight / 50)
          let rowHeight = parseInt(winHeight / max)
          // console.log(winHeight, rowHeight, 'height');
          let els =  body.getElementsByClassName("polling")
          for(let el of els){
              el.style = `height: ${rowHeight}px !important; box-sizing: border-box;`
            };
          // let body = _this.$refs.leftbar3
          // let winHeight = document.getElementsByClassName("polling")[0].offsetHeight;
          // body.scrollTop = 0;
          _this.timer2 = setInterval(async () => {
            if (_this.active < this.lineDatas.list.length) {
              _this.sj(rowHeight,body)
              // this.active += 1;
              // for (let i = 0; i <= rowHeight; i++) {
              //   await _this.wait(0.5 / rowHeight);
              //   body.scrollTop = body.scrollTop + 1;
              //   if (body.scrollTop >= this.active * rowHeight) { 
              //     body.scrollTop = parseInt(this.active * rowHeight);
              //     break;
              //   }
              // }
            } else {
              body.scrollTop = 0;
              _this.active = 0;
              _this.sj(rowHeight,body)
            }
          }, 1500);
        }, 200);
      }catch(e){
        console.log(e, 'error');
      }
    },
    async sj(rowHeight,body){
      // console.log(rowHeight,body, 'sj---------');
    let _this = this;
      if (this.active < this.pollingList.length) {
        this.active += 1;
        for (let i = 0; i <= rowHeight; i++) {
          await _this.wait(0.5 / rowHeight);              
          body.scrollTop = Math.ceil(body.scrollTop + 1);
          if (body.scrollTop >= this.active * rowHeight) {
            body.scrollTop = parseInt(this.active * rowHeight);
            break;
          }
        }
      } else {
        body.scrollTop = 0;
        this.active = 0;
      }
    },
    wait(time = 0) {
      return new Promise((res, rej) => setTimeout(() => res(), time * 1000));
    },
  },
};
</script>
<style lang="scss">
.el-table::before {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.script {
  position: fixed;
  top: 0.1rem;
  left: 0.1rem;
  // left: 0.43rem;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6eb4ff;
  cursor: pointer;
}
.entrance {
  width: 108px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: url("../../../assets/biIndex/entrance1.png") top center no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-style: italic;
  color: #ffff;
  cursor: pointer;
}
.entrance:hover {
  background: url("../../../assets/biIndex/entrance.png") top center no-repeat;
}
.rightbar {
  flex: 1;
  width: 100%;
  // padding-left: 0.05rem;
  background-color: rgba(#0F2545,  .96);
}
.bgbox {
  position: fixed;
  z-index: 8;
  width: 4.8rem;
  left: 0;
  /* background: linear-gradient(-90deg, rgba(5, 25, 61, 0) 0%, #05193d 60%); */
}
/* .hovers { */
  /* background: linear-gradient(-90deg, rgba(5, 25, 61, 0) 0%, #05193d 60%); */
/* } */
.nohovers {
  width: 0.1rem;
  background: none;
}
.bibox {
  transition: left 0.5s;
  position: fixed;
  z-index: 9;
  top: 1rem;
  left: 0;
  height: calc(100vh - 1.2rem);
  text-align: center;
  color: let(--title-text-color);
  display: flex;
  justify-content: flex-end;
  // padding-top: 0.57rem;
  background: linear-gradient(-90deg, rgba(15, 37, 69, 0) 0%, #0f2545 60%);
}
.chart-box {
  width: 4.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-item {
  width: 100%;
  height: 36.8%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    rgba(8, 65, 100, 0.4),
    rgba(27, 46, 65, 0.4)
  );
  .chart-title {
    color: #fff;
    text-align: left;
    position: relative;
    height: 35px;
    p,
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    p {
      padding-left: 35px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      /* font-style: italic; */
      line-height: 32px;
      text-shadow: 3px 3px 3px rgba(0, 9, 17, 0.52);
    }
  }
  .chart-footer {
    width: 100%;
    overflow: hidden;
  }
}
.pipe {
  height: 25%;
  padding-bottom: 0.1rem;
}
.pipe2{
  height: 30%;
}
.pipe3{
  height: 43%;
}
.pipe_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 2.14rem;
}
.pipeBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1.88rem;
  height: 0.88rem;
  padding: 0 0 0 0.05rem;
  margin: 0.1rem 0rem 0 0.1rem;
  text-align: left;
  .pipeBox_img {
    width: 0.82rem;
    height: 0.87rem;
    margin: 0 0.05rem 0 0;
    .pipe_img {
      width: 100%;
      height: 100%;
    }
  }
  .pipeBox_number {
    font-size: 0.3rem;
    font-weight: bold;
    color: #ffffff;
    height: 0.4rem;
  }
  .pipeBox_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #2f9aff;
  }
}
// .rLeft {
//   position: relative;
// }
.Putaway {
  background: #182c3e;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  right: -0.1rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.46rem;
}
.titleContext__between {
  display: flex;
  justify-content: space-between;
}
.more-text__btn {
  padding-right: 25px;
  font-size: 14px;
  font-weight: 400;
  color: #73f7ff;
  line-height: 40px;
  font-style: normal;
  cursor: pointer;
}
.polling {
  padding: 0.1rem 0.1rem;
  height: 0.4rem;
  .polling_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .person_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .person_name {
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00f6ff;
      }
      .person_img {
        width: 0.09rem;
        height: 0.1rem;
        margin-left: 0.08rem;
      }
    }
    .person_num {
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .schedule {
    height: 0.16rem;
    line-height: 0.16rem;
    border: 1px solid rgba(223, 254, 255, 0.15);
    .schedule_box {
      width: 3.62rem;
      height: 0.04rem;
      background: rgba(94, 167, 255, 0.3);
      border-radius: 0.02rem;
      margin: 0.06rem auto;
      position: relative;
      .schedule_img {
        position: absolute;
        top: -0.06rem;
        width: 0.2rem;
        transition-duration: 1s;
        height: 0.16rem;
      }
    }
    .schedule_fill {
      width: 1rem;
      height: 0.04rem;
      background: linear-gradient(
        90deg,
        rgba(236, 170, 29, 0.5) 50%,
        rgba(182, 144, 56, 0.85),
        #ecaa1d
      );
      transition-duration: 1s;
      border-radius: 2px;
    }
    .one {
      width: 0rem !important;
    }
    .two {
      left: -0.1rem !important;
    }
  }
}
.pp1 {
  overflow-y: auto;
  height: 2.9rem;
  position: relative;
  .slide-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // .slide-item {
    //   // width: 100%;
    //   height: 0.5rem;
    // }
  }
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
