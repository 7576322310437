<template>
  <div style="height: 100%;display:flex;">
    <el-table
      id="dbM1"
      ref="scrollTable"
      height="calc(94% - 1px)"
      :data="tableData"
      :header-cell-style="{
        height: '30px',
        width:'100%',
        color: '#5BD3FF',
        fontSize: '14px',
        border:'0'
      }"
      :cell-style="cellStyle"
      @mouseenter.native="clearIntervalTimer"
      @mouseleave.native="setScroll"
    >
      <el-table-column
        v-for="(item, index) in head"
        :key="index"
        :align="item.align||'left'"
        :label="item.label"
        :show-overflow-tooltip="true"
        :prop="item.prop"
        :min-width="item.label.length * 6 + 30"
        :width="item.width||''"
      >
        <!--todo 二级表头-->
        <el-table-column
          v-for="(subItem, index1) in item.child"
          :key="index1"
          :label="subItem.label"
        >
          <template v-if="!subItem.child">
            {{ subItem.val }}
          </template>
          <!--todo 三级表头-->
          <el-table-column
            v-for="(subItem2, index2) in subItem.child"
            :key="index2"
            :label="subItem2.label"
          >
            <template v-if="!subItem2.child">
              {{ subItem2.val }}
            </template>
          </el-table-column>
        </el-table-column>
        <template v-if="item.slotName" #default="{ row }">
          <slot :name="item.slotName" :row="row" :col="item"></slot>
        </template>
      </el-table-column>
      <slot name="footerTable"></slot>
    </el-table>
  </div>
</template>

<script>
// import { wait } from '@/utils'
export default {
  name: 'DtSrcoll1',
  props: {
    data: { type: Array, default: () => [] },
    head: { type: Array, default: () => [] },
    rate: { type: Number, default: 1600 },
    newData: { type: Array, default: () => [] }, //表格数据
    maxRowNum: {
      //页面需要显示的行数
      type: Number,
      default: 5
    },

    duration: {
      //过渡时间
      type: Number,
      default: 500
    },

    isClear: {
      //数据滚动到最后一行是否停止滚动
      type: Boolean,
      default: false
    },
    isAgain: {
      // 数据滚动到最后一行是否重新开始滚动
      type: Boolean,
      default: true
    },
    isScroll: {
      //是否允许内容滚动
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: 0,
      timer1: '',
      tableData: []
    }
  },
  watch: {
    data: {
      handler(v) {
        clearInterval(this.timer1)
        this.setScroll()
      },
      immediate: true
    }
  },
  mounted() {},
  destroyed() {
    // clearInterval(this.timer1)
    this.clearIntervalTimer()
  },
  methods: {
    clearIntervalTimer() {
      this.timer1 && clearInterval(this.timer1)
    },
    cellStyle({row, column, rowIndex, columnIndex}){
      let color,background
      if(rowIndex%2) {
        background = 'rgba(0, 115, 222, 0.1)'
      } else {
         background = 'rgba(0, 115, 222, 0.2)'
      }
      if(column.property == "notRectifyNum") {
        // 异常数
        color = 'rgba(255, 44, 44, 1)'
      } else if(column.property == "rectifyNum") {
        // 已完成
        color = 'rgba(50, 255, 175, 1)'
      }
       else {
        color = '#ffffff'
      }
      return {color,background,border:'none'}
    },
    setScroll() {
      if (this.data.length < this.maxRowNum) return (this.tableData = this.data)
      this.tableData = [...this.data, ...this.data]
      let _this = this
      setTimeout(() => {
        let table = this.$refs.scrollTable
        let body = table.$refs.bodyWrapper
        let rowHeight = parseInt(body.clientHeight / this.maxRowNum)
        body.style.height = rowHeight * this.maxRowNum
        let elRows = body.getElementsByClassName('el-table__row')
        for (let row of elRows) {
          row.style.height = rowHeight + 'px'
        }
        // body.scrollTop = 0
        if (this.isScroll) {
          this.timer1 = setInterval(async () => {
            if (this.active < this.data.length) {
              // this.active += 1
              // for (let i = 0; i <= rowHeight; i++) {
              //   await _this.wait(0.5 / rowHeight)
              //   body.scrollTop = Math.ceil(body.scrollTop + 1)
              //   if (body.scrollTop >= this.active * rowHeight) {
              //     body.scrollTop = parseInt(this.active * rowHeight)
              //     break
              //   }
              // }
              _this.sj(rowHeight,body)
            } else {
              if (this.isClear) {
                clearInterval(this.timer1)
              }
              if (this.isAgain) {
                this.active = 0
                body.scrollTop = 0
                _this.sj(rowHeight,body)
              } else {
                clearInterval(this.timer1)
              }
            }
          }, 1500)
        }
      }, 200)
    },
    async sj(rowHeight,body){
    let _this = this;
       if (this.active < this.data.length) {
            this.active += 1;
            for (let i = 0; i <= rowHeight; i++) {
              await _this.wait(0.5 / rowHeight);              
              body.scrollTop = Math.ceil(body.scrollTop + 1);
              if (body.scrollTop >= this.active * rowHeight) {
                body.scrollTop = parseInt(this.active * rowHeight);
                break;
              }
            }
          } else {
            body.scrollTop = 0;
            this.active = 0;
          }
    },
    wait(time = 0) {
      return new Promise((res, rej) => setTimeout(() => res(), time * 1000))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__body {
  position: absolute;
  transition: all 500ms linear;
}
::v-deep.el-table .el-table__header .el-table__cell{
  background-color: transparent !important
}
::v-deep .el-table tr {
  background: none;
}
::v-deep th.el-table__cell {
  background: none;
  
}
::v-deep .el-table th.el-table__cell>.cell{
padding-left:0 ;
  padding-right: 0;
}
::v-deep .el-table .cell{
  padding-left:0 ;
  padding-right: 0;
}
</style>
<style lang="scss">
#dbM1 {
  background: none;
  // width: 100%;
  // height: 100%;
  margin: 0.1rem;
  // border: 0.01rem solid #ffffff;
  .el-table__header{
colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }
  }
  .el-table__body-wrapper {
    
    &::-webkit-scrollbar {
      width: 0rem;
      display:none;
    }
    ::-webkit-scrollbar-track {
      // 滚动条轨道
      border: none;
    }
    
      .el-table__body {
        width: 100% !important;
      }
  }
  .gutter {
    width: 0 !important;
    display: none;
    
  }
  .el-table__header-wrapper {
    width: 100%;
    /* background: linear-gradient(90deg, rgba(18, 159, 252, 0.48), rgba(18, 159, 252, 0.12)); */
    background: rgba(0,115,222,0.3);
    border: 1px solid #0050AC;
  }
}
</style>
